import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { AccountsService } from "../accounts.service";
import { Customer } from "../customer";

@Injectable({
    providedIn: "root"
})
export class AccountListResolverService implements Resolve<Customer[]> {
    constructor(private as: AccountsService) {}

    resolve(): Observable<Customer[]> | Observable<never> {
        return Observable.create((observe: Subscriber<Customer[]>) => {
            this.as.refreshAccounts().subscribe((accounts: Customer[]) => {
                observe.next(accounts);
                observe.complete();
            });
        });
    }
}
