import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";

import { Constants } from "../../../constants/constants";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";
import { AccountsService } from "../accounts.service";
import { Customer } from "../customer";
import { filter } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { KeyMap } from "src/app/models/shared";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";

@Component({
    selector: "app-account-list",
    templateUrl: "./account-list.component.html"
})
export class AccountListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    accountId: number;
    accounts: Customer[];
    selectedRows: Array<Customer> = [];

    urls = Constants.urls;

    isZixiAdmin: boolean;
    isZixiSupport: boolean;
    private isZixiAdminSubscription: Subscription;
    private isZixiSupportSubscription: Subscription;

    private routeSubscription: Subscription;
    private accountsSubscription: Subscription;
    private accountsRefreshSubscription: Subscription;

    private accountsBS$ = new BehaviorSubject<Customer[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Customer>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: KeyMap<Customer>) => row.name,
            textValue: (row: KeyMap<Customer>) => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("DNS_PREFIX"),
            columnDef: "dns_prefix",
            width: 140,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Customer>>(
                row => row.dns_prefix,
                row => row.dns_prefix,
                () => true
            ),
            sortBy: (row: KeyMap<Customer>) => row.dns_prefix,
            textValue: (row: KeyMap<Customer>) => row.dns_prefix,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("ENABLED"),
            columnDef: "is_enabled",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Customer>>(
                row => (row.is_enabled ? "Yes" : "No"),
                row => (row.is_enabled ? "Yes" : "No"),
                () => true
            ),
            sortBy: (row: KeyMap<Customer>) => (row.is_enabled ? "Yes" : "No"),
            textValue: (row: KeyMap<Customer>) => (row.is_enabled ? "Yes" : "No"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: (row: KeyMap<Customer>) => (row.is_enabled ? "Yes" : "No")
        },
        {
            header: this.translate.instant("CQA"),
            columnDef: "content_analysis",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Customer>>(
                row => (row.content_analysis ? "Yes" : "No"),
                row => (row.content_analysis ? "Yes" : "No"),
                () => true
            ),
            sortBy: (row: KeyMap<Customer>) => (row.content_analysis ? "Yes" : "No"),
            textValue: (row: KeyMap<Customer>) => (row.content_analysis ? "Yes" : "No"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: (row: KeyMap<Customer>) => (row.content_analysis ? "Yes" : "No")
        },
        {
            header: this.translate.instant("TRACEROUTE_HISTORY"),
            columnDef: "traceroute_history",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Customer>>(
                row => (row.traceroute_history ? "Yes" : "No"),
                row => (row.traceroute_history ? "Yes" : "No"),
                () => true
            ),
            sortBy: (row: KeyMap<Customer>) => (row.traceroute_history ? "Yes" : "No"),
            textValue: (row: KeyMap<Customer>) => (row.traceroute_history ? "Yes" : "No"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: (row: KeyMap<Customer>) => (row.traceroute_history ? "Yes" : "No")
        },
        {
            header: this.translate.instant("AUTOMATION"),
            columnDef: "automation",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Customer>>(
                row => (row.automation ? "Yes" : "No"),
                row => (row.automation ? "Yes" : "No"),
                () => true
            ),
            sortBy: (row: KeyMap<Customer>) => (row.automation ? "Yes" : "No"),
            textValue: (row: KeyMap<Customer>) => (row.automation ? "Yes" : "No"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: (row: KeyMap<Customer>) => (row.automation ? "Yes" : "No")
        },
        {
            header: this.translate.instant("AGENTZ"),
            columnDef: "allow_agentz",
            width: 80,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<Customer>>(
                row => (row.allow_agentz ? "Enabled" : "Disabled"),
                row => (row.allow_agentz ? "Enabled" : "Disabled"),
                () => true
            ),
            sortBy: (row: KeyMap<Customer>) => (row.allow_agentz ? "Enabled" : "Disabled"),
            textValue: (row: KeyMap<Customer>) => (row.allow_agentz ? "Enabled" : "Disabled"),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Enabled", "Disabled"],
            columnFilterValue: (row: KeyMap<Customer>) => (row.allow_agentz ? "Enabled" : "Disabled")
        }
    ];

    constructor(
        private router: Router,
        private as: AccountsService,
        private sharedService: SharedService,
        private userService: UsersService,
        private translate: TranslateService,
        private titleService: TitleService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("ACCOUNTS"));
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.id) {
                    this.accountId = parseInt(event.snapshot.params.id, 10);
                    this.updateSelectedRows();
                } else {
                    this.accountId = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        this.loading = true;

        this.isZixiAdminSubscription = this.userService.isZixiAdmin.subscribe(bool => {
            this.isZixiAdmin = bool;
        });

        this.isZixiSupportSubscription = this.userService.isZixiSupport.subscribe(bool => {
            this.isZixiSupport = bool || this.isZixiAdmin;
        });

        this.accountsSubscription = this.as.accounts.subscribe(accounts => {
            this.accounts = accounts;
            if (this.accounts) {
                this.prepTableData();
                this.updateSelectedRows();
                this.loading = false;
            }
        });

        // Start Auto Refresh
        this.startAccountsRefresh();

        setTimeout(() => {
            this.updateSelectedRows();
        }, 0);
    }

    ngOnDestroy() {
        this.accountsSubscription.unsubscribe();
        this.isZixiAdminSubscription.unsubscribe();
        this.isZixiSupportSubscription.unsubscribe();
        this.stopAccountsRefresh();
    }

    domainsReport() {
        /*download(
            [["Name", "DNS Prefix", "Enabled", "Admin Name", "Admin Email"].join(",")]
                .concat(
                    _.map(this.accounts, (domain) => {
                        return [domain.name, domain.dns_prefix, domain.is_enabled].join(",");
                    })
                )
                .join("\n"),
            "zen-accounts.csv",
            "data:text/csv"
        );*/
    }

    selectRow(account: Customer) {
        this.router.navigate([Constants.urls.accounts, account.id]);
    }

    updateSelectedRows() {
        if (this.accountId) {
            const existing = this.accounts?.find(account => account.id === this.accountId);
            this.selectedRows = existing ? [existing] : [];
        }
    }

    async refresh() {
        this.refreshing = true;
        const accountsRefresh = this.as.refreshAccounts(true).toPromise();
        await Promise.all([accountsRefresh]);
        this.refreshing = false;
    }

    startAccountsRefresh() {
        this.accountsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopAccountsRefresh() {
        this.accountsRefreshSubscription.unsubscribe();
    }

    get accounts$() {
        return this.accountsBS$.asObservable();
    }

    private prepTableData() {
        if (this.accounts) {
            this.accounts = [...this.accounts];
            this.accountsBS$.next(this.accounts);
        }
    }

    async multiAction(action: string, func: (account: Customer) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "ACCOUNT", this.selectedRows, func);
        if (!result.keepSelected && action !== "DELETE") this.selectedRows = [];
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple accounts");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (account: Customer) => this.as.deleteAccount(account));
    }
}
