import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription, Observable } from "rxjs";

import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
//
import { AccountsService } from "../accounts.service";
import { Customer } from "../customer";
import { UsersService } from "../../account-management/users/users.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";

@Component({
    selector: "app-account",
    templateUrl: "./account.component.html"
})
export class AccountComponent implements OnInit, OnDestroy {
    account: Customer;
    accountId: number;

    isZixiSupport: boolean;

    private accountsSubscription: Subscription;

    constants = Constants;

    loadingDetails = true;

    isZixiAdmin$: Observable<boolean>;
    isZixiSupport$: Observable<boolean>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private as: AccountsService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        this.route.paramMap.subscribe(params => {
            this.accountId = parseInt(params.get("id"), 10);
            if (this.accountId) this.account = this.as.getCachedAccount(null, this.accountId);
            if (!this.account) return this.cancel();

            // Set Title
            this.titleService.setTitle(this.translate.instant("ACCOUNT") + " - " + this.account.name);
        });
    }

    ngOnInit() {
        this.accountsSubscription = this.as.accounts.subscribe(accounts => {
            this.account = accounts.find(a => a.id === this.accountId);
        });

        this.isZixiAdmin$ = this.userService.isZixiAdmin;
        this.isZixiSupport$ = this.userService.isZixiSupport;
    }

    ngOnDestroy() {
        if (this.accountsSubscription) this.accountsSubscription.unsubscribe();
    }

    cancel() {
        this.gotoAccounts();
    }

    gotoAccounts() {
        this.router.navigate([Constants.urls.accounts]);
    }

    impersonate() {
        window.location.href = `/api/impersonate/${this.account.dns_prefix}`;
    }

    async toggle() {
        if (this.account.is_enabled) {
            const model = {
                is_enabled: false
            };

            await this.modalService.confirm(
                "DISABLE",
                "ACCOUNT",
                async () => {
                    const result = await this.as.updateAccount(this.account.id, model);
                    if (result) {
                        this.mixpanelService.sendEvent("disable account", {
                            id: this.account.id
                        });
                    } else return false;
                },
                this.account.name
            );
        } else {
            this.router.navigate([Constants.urls.accounts, this.account.id, "enable"]);
        }
    }

    async deleteAccount() {
        await this.modalService.confirm(
            "DELETE",
            "ACCOUNT",
            async () => {
                const id = this.account.id;
                const result = await this.as.deleteAccount(this.account);
                if (result) {
                    this.mixpanelService.sendEvent("delete account", { id });
                    this.router.navigate([Constants.urls.accounts]);
                } else return false;
            },
            this.account.name
        );
    }

    editAccount(): void {
        this.router.navigate([Constants.urls.accounts, this.account.id, "edit"]);
    }
}
