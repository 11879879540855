import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";

import { Constants } from "../../constants/constants";
import { AccountFormComponent } from "./account-form/account-form.component";
import { AccountsComponent } from "./accounts.component";
import { AccountListComponent } from "./account-list/account-list.component";
import { AccountListResolverService } from "./account-list/account-list-resolver.service";
import { AccountComponent } from "./account/account.component";

const accountRoutes: Routes = [
    {
        path: Constants.urls.accounts + "/new",
        component: AccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.accounts + "/:id/:action",
        component: AccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.accounts,
        component: AccountListComponent,
        canActivate: [AuthGuard],
        resolve: {
            account: AccountListResolverService
        },
        children: [
            {
                path: ":id",
                component: AccountComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(accountRoutes)],
    exports: [RouterModule]
})
export class AccountsRouting {}
