import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { MatMenuModule } from "@angular/material/menu";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { AccountsComponent } from "./accounts.component";
import { AccountFormComponent } from "./account-form/account-form.component";
import { AccountListComponent } from "./account-list/account-list.component";

// Routes
import { AccountsRouting } from "./accounts.routing";
import { AccountComponent } from "./account/account.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        MatMenuModule,
        FontAwesome,
        SharedModule,
        AccountsRouting
    ],
    declarations: [AccountsComponent, AccountComponent, AccountFormComponent, AccountListComponent]
})
export class AccountsModule {}
