import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import * as _ from "lodash";

import { Constants } from "../../../constants/constants";
import { SharedService } from "../../../services/shared.service";
import { AccountsService } from "../accounts.service";
import { IoServersService, IoServer } from "../../../components/io-servers/io-servers.service";
import { Customer } from "../customer";
import { TitleCasePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { ClipboardService } from "ngx-clipboard";

interface AMIOption {
    value: string;
    name: string;
}

@Component({
    selector: "app-account-form",
    templateUrl: "./account-form.component.html",
    providers: [TitleCasePipe]
})
export class AccountFormComponent implements OnInit, OnDestroy {
    account: Customer;
    existingAccount: Customer;
    accountID: number;
    action: string;
    blacklist: string[] = ["zixi"];

    loading = true;
    routeLoading = true;
    saving = false;
    submitted = false;
    isEdit = false;
    isEnable = false;
    constants = Constants;

    private ioServersSubscription: Subscription;

    adminName: string;
    adminEmail: string;
    adminPassword: string;
    ioServer: IoServer;
    ioServers: IoServer[];
    instanceTypes = ["t4g.medium", "t4g.xlarge", "t4g.2xlarge"];
    amis: Record<string, AMIOption[]>;
    regions: string[] = [];
    tunnelServerIds: number[];
    showPassword: boolean;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private sharedService: SharedService,
        private as: AccountsService,
        private ios: IoServersService,
        private translate: TranslateService,
        private titleService: TitleService,
        private titlecasePipe: TitleCasePipe,
        private cbs: ClipboardService
    ) {
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.route.paramMap.subscribe(params => {
            this.accountID = parseInt(params.get("id"), 10);
            this.action = params.get("action");

            if (this.accountID) {
                this.account = Object.assign({}, this.as.getCachedAccount(null, this.accountID));
                this.existingAccount = _.cloneDeep(this.account);
                if (this.sharedService.isEmptyObject(this.account)) {
                    this.as
                        .refreshAccounts(true)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.account = Object.assign({}, this.as.getCachedAccount(null, this.accountID));
                            this.existingAccount = _.cloneDeep(this.account);
                            this.prepForm();
                            this.routeLoading = false;
                        });
                } else {
                    this.routeLoading = false;
                }
            } else {
                this.routeLoading = false;
            }
        });
    }

    async ngOnInit() {
        if (this.action === "edit") this.isEdit = true;
        if (this.action === "enable") this.isEnable = true;

        this.ioServersSubscription = this.ios.ioServers.subscribe(ioServers => {
            this.ioServers = ioServers;
        });

        await this.getOptions();
        this.prepForm();

        this.loading = false;
    }

    async getOptions() {
        const result = await this.ios.options();
        if (result) {
            const now = Date.now();
            this.amis = _.mapValues(result, amis => {
                const sortedAmis = _.sortBy(amis, ami => {
                    return now - new Date(ami.created_at).valueOf();
                });
                return sortedAmis.map(ami => ({ value: ami.ami_id, name: ami.description }));
            });
            this.regions = Object.keys(this.amis);
        } else {
            this.amis = {};
            this.regions = [];
        }
    }

    regionChange() {
        this.ioServer.ami = this.amis?.[this.ioServer.region]?.[0]?.value;
    }

    prepForm() {
        if (!this.account && !this.isEdit && !this.isEnable) {
            this.account = new Customer();
            this.account.content_analysis = 1;
            this.account.traceroute_history = 0;
            this.account.automation = 1;
            this.account.live_events = 0;
            this.account.allow_agentz = false;
            this.ioServer = new IoServer();
            this.ioServer.region = "us-east-1";
            this.ioServer.instance_type = "t4g.medium";
            this.ioServer.ami = this.amis?.[this.ioServer.region]?.[0]?.value;
        } else if (this.isEnable) {
            this.ioServer = new IoServer();
            this.ioServer.region = "us-east-1";
            this.ioServer.instance_type = "t4g.medium";
            this.ioServer.ami = this.amis?.[this.ioServer.region ?? "us-east-1"]?.[0]?.value;
        }

        // Set Title
        this.titleService.setTitle(
            this.translate.instant("ACCOUNT") +
                " - " +
                (this.action ? this.titlecasePipe.transform(this.action) : "New") +
                " " +
                (this.account && this.account.name ? this.account.name : "")
        );
    }

    ngOnDestroy() {
        this.ioServersSubscription.unsubscribe();
    }

    async onSubmit() {
        this.saving = true;

        if (this.isEnable) {
            const model = {
                is_enabled: true,
                tunnel_server: {
                    instance_type: this.ioServer.instance_type,
                    ami: this.ioServer.ami,
                    region: this.ioServer.region,
                    eip: this.ioServer.eip,
                    dns_prefix: this.account.dns_prefix,
                    shared: false
                }
            };
            const result = await this.as.updateAccount(this.account.id, model);
            if (result) this.router.navigate([Constants.urls.accounts]);
            else this.saving = false;
        } else if (this.isEdit) {
            const model = {
                name: this.account.name,
                tunnel_server_ids: this.tunnelServerIds,
                content_analysis: this.account.content_analysis,
                traceroute_history: this.account.traceroute_history,
                automation: this.account.automation,
                live_events: this.account.live_events,
                max_bx_version: this.account.max_bx_version,
                allow_agentz: this.account.allow_agentz
            };
            const result = await this.as.updateAccount(this.account.id, model);
            if (result) this.router.navigate([Constants.urls.accounts]);
            else this.saving = false;
        } else {
            const model = {
                name: this.account.name,
                dns_prefix: this.account.dns_prefix,
                admin_name: this.adminName,
                admin_email: this.adminEmail,
                admin_password: this.adminPassword,
                content_analysis: this.account.content_analysis,
                traceroute_history: this.account.traceroute_history,
                automation: this.account.automation,
                live_events: this.account.live_events,
                max_bx_version: this.account.max_bx_version,
                tunnel_server_ids: [],
                tunnel_server: {
                    instance_type: this.ioServer.instance_type,
                    ami: this.ioServer.ami,
                    region: this.ioServer.region,
                    eip: this.ioServer.eip,
                    dns_prefix: this.account.dns_prefix,
                    shared: false
                },
                allow_agentz: this.account.allow_agentz
            };

            const result = await this.as.addAccount(model);
            if (result) this.router.navigate([Constants.urls.accounts]);
            else this.saving = false;
        }
    }

    cancel() {
        if (this.isEdit || this.isEnable) this.router.navigate([Constants.urls.accounts, this.accountID]);
        else this.router.navigate([Constants.urls.accounts]);
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }
}
